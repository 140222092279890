<template>
  <v-card>
    <h4 class="text-h4 mb-2">
      {{ $t("profile.addresses.cartWarning") }}
    </h4>
    <div v-for="(removeCartItemsGroup, index) in cartItems" :key="index">
      <v-row
        v-for="item in removeCartItemsGroup.cartItems"
        :key="item.product.productId"
      >
        <v-col cols="2">
          <img
            width="70"
            height="70"
            :src="item.product.mediaURL"
            :alt="item.product.name"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="my-0"
        /></v-col>
        <v-col>
          <div class="flex-grow px-1">
            <div class="text-body-2">
              {{ item.product.name }}
            </div>
            <div class="font-weight-bold text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
            <div class="text-uppercase">
              {{ item.product.description }}
            </div>
            <div v-if="item.cartItemInfo.size" class="cart-item-info">
              <i
                >{{ $t("product.preferences") }}:
                {{ item.cartItemInfo.size }}</i
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-card-action class="d-flex justify-end gap-2">
      <v-btn color="primary" large outlined depressed @click="undo()">
        Annulla
      </v-btn>

      <v-btn color="primary" large depressed @click="setAddress()">
        Continua
      </v-btn>
    </v-card-action>
  </v-card>
</template>
<style lang="scss"></style>
<script>
import cartInfo from "~/mixins/cartInfo";
import categoryMixin from "~/mixins/category";

export default {
  name: "AddressSelector",
  mixins: [cartInfo, categoryMixin],
  props: {
    address: { type: Object, required: true },
    cartItems: { type: Array, required: true }
  },
  methods: {
    async setAddress() {
      this.$emit("submit", true);
    },
    undo() {
      this.$emit("submit", false);
    }
  },
  mounted() {
    console.log(this.address);
    console.log("mounted");
  }
};
</script>
